import Cookies from 'js-cookie';

function browserVersion() {
  let _browser = {}
  let userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
  let isIE = userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1; //判断是否IE<11浏览器
  let isIE11 = userAgent.indexOf('Trident') > -1 && userAgent.indexOf("rv:11.0") > -1;
  let isEdge = userAgent.indexOf("Edge") > -1 && !isIE; //Edge浏览器
  let isFirefox = userAgent.indexOf("Firefox") > -1; //Firefox浏览器
  let isOpera = userAgent.indexOf("Opera") > -1 || userAgent.indexOf("OPR") > -1; //Opera浏览器
  let isChrome = userAgent.indexOf("Chrome") > -1 && userAgent.indexOf("Safari") > -1 && userAgent.indexOf("Edge") == -1 && userAgent.indexOf("OPR") == -1; //Chrome浏览器
  let isSafari = userAgent.indexOf("Safari") > -1 && userAgent.indexOf("Chrome") == -1 && userAgent.indexOf("Edge") == -1 && userAgent.indexOf("OPR") == -1; //Safari浏览器
  if (isIE) {
    _browser.name = 'IE';
    let reIE = new RegExp("MSIE (\\d+\\.\\d+);");
    reIE.test(userAgent);
    let fIEVersion = parseFloat(RegExp["$1"]);
    _browser.version = fIEVersion > 6 ? fIEVersion : 6;
  } 
  if (isIE11) {
    _browser.name = 'IE';
    _browser.version = 11;
  }
  if (isEdge) {
    _browser.name = 'Edge';
    _browser.version = userAgent.split('Edge/')[1].split('.')[0];
  } 
  if (isFirefox) {
    _browser.name = 'Firefox';
    _browser.version = userAgent.split('Firefox/')[1].split('.')[0];
  } 
  if (isOpera) {
    _browser.name = 'Opera';
    _browser.version = userAgent.split('OPR/')[1].split('.')[0];
  } 
  if (isChrome) {
    _browser.name = 'Chrome';
    _browser.version = userAgent.split('Chrome/')[1].split('.')[0];
  }
  if (isSafari) {
    _browser.name = 'Safari';
    _browser.version = userAgent.split('Safari/')[1].split('.')[0];
  }
  return _browser
}

const _browerRule = {
  'IE': 9,
  'Edge': 17,
  'Chrome': 49,
  'Firefox': 68,
  'Safari': 12,
  'Opera': 63
}

function uaMatch(){
  let _browser = browserVersion()
  if (_browser){
    if (Number(_browser.version) >= _browerRule[_browser.name]) {
      console.log(`当前浏览器版本：${_browser.name + _browser.version}，不需要被拦截`)
      return;
    }
    console.log('browserIntercept', _browser.name + _browser.version);
    console.log(`当前浏览器版本：${_browser.name + _browser.version}，需要被拦截`)
  }
  else{
    console.log(`未知浏览器版本，暂不拦截`)
  }
}

export {
  uaMatch
};