const cn2en = {};

cn2en['账户管理'] = 'Account Management';
cn2en['基本资料'] = 'Basic Information';
cn2en['安全设置'] = 'Security Settings';
cn2en['实名认证'] = 'Real-name Verification';
cn2en['学生认证'] = 'Student Verification';
cn2en['联系人管理'] = 'Contacts Management';
cn2en['AccessKey管理'] = 'AccessKey Management';
cn2en['虚拟MFA认证'] = 'Virtual MFA Authentication';

cn2en['资金管理'] = 'Funds Management';
cn2en['资金账户'] = 'Funds Account';
cn2en['充值'] = 'Recharge';
cn2en['线下汇款单管理'] = 'Offline Money Order Management';

cn2en['消费管理'] = 'Consumption Management';
cn2en['消费总览'] = 'Consumption Overview';
cn2en['资源账单'] = 'Resource Bill';
cn2en['消费记录'] = 'Purchase History';

cn2en['订单管理'] = 'Order Management';

cn2en['代金券管理'] = 'Coupon Management';

cn2en['发票管理'] = 'Invoice Management';
cn2en['发票列表'] = 'Invoice List';
cn2en['发票信息管理'] = 'Invoice Information Management';
cn2en['邮寄地址管理'] = 'Mail Address Management';

cn2en['费用管理'] = 'Cost Management';

cn2en['消息管理'] = 'Message Management';
cn2en['我的消息'] = 'My Messages';
cn2en['消息设置'] = 'Message Settings';

cn2en['工单管理'] = 'Ticket Management';
cn2en['提交工单'] = 'Submit Ticket';
cn2en['我的工单'] = 'My Tickets';

cn2en['账号管理'] = 'Account Management';
cn2en['重置密码'] = 'Reset Password';

cn2en['二级菜单开关'] = 'Secondary menu switch';
cn2en['展开或收起二级菜单'] = 'Expand or collapse the secondary menu';

cn2en['当前有效身份：'] = 'Current valid identity:';
cn2en['角色：'] = 'Role：';
cn2en['登录角色：'] = 'Role：';
cn2en['目录账户：'] = 'Directory Account：';
cn2en['所属账户：'] = 'Account：';
cn2en['所属账户ID：'] = 'Account ID：';

cn2en['当前登录身份'] = 'Current login identity';
cn2en['联合身份角色：'] = 'Union identity role：';
cn2en['当前有效会话：'] = 'Current active session：';

cn2en['当前登录为：'] = 'Current login as:';
cn2en['子账号：'] = 'Sub-account：';
cn2en['所属企业：'] = 'Account：';
cn2en['角色历史纪录：'] = 'Record of role history:';
cn2en['切换角色'] = 'Switch Role';
cn2en['返回登录身份'] = 'Back to Sub-account';

cn2en['合同管理'] = 'Contract Management';
cn2en['合同申请'] = 'Contract Application';
cn2en['甲方信息管理'] = 'Certificates Management';

cn2en['我的信息'] = 'My Information';
cn2en['我的客户'] = 'My Customer';
cn2en['客户管理'] = 'Customer Management';
cn2en['下级服务商客户'] = 'My Provider Customer';
cn2en['我的下级服务商'] = 'My Provider';
cn2en['下级服务商列表'] = 'My Provider List';
cn2en['业务数据'] = 'Business Data';
cn2en['我的销售业绩'] = 'My Performance';
cn2en['我的服务商销售业绩'] = 'My Provider Performance';
cn2en['客户消费总览'] = 'Customer Comsumption';
cn2en['客户预付费明细'] = 'Customer Prepaid Detail';
cn2en['客户后付费明细'] = 'Customer Postpaid Detail';
cn2en['返还管理'] = 'Rebate Management';
cn2en['返还单'] = 'Rebate';

cn2en['账户余额'] = "Account Balance";
cn2en['欠费'] = "Arrears";
cn2en['账户管理①'] = "Account";
cn2en['安全设置①'] = "Settings";
cn2en['Access Key管理'] = "Access Key";
cn2en['退出'] = "Logout";
cn2en['返回'] = "Sign Out";
cn2en['MFA认证'] = "MFA Auth";

cn2en['HOT'] = "hot";
cn2en['NEW'] = "new";
cn2en['公测中'] = "beta";
cn2en['内测中'] = "test";
cn2en['敬请期待'] = "tuned";

cn2en['请输入关键字，如云主机'] = 'Entry keywords';
cn2en['全站搜索'] = 'Search'; 
cn2en['热门搜索'] = 'Hot Search';

cn2en['云主机'] = 'Virtual Machine';
cn2en['云硬盘'] = ' Cloud Disk Service';
cn2en['对象存储'] = 'Object Storage Service';
cn2en['域名'] = 'Domain Name Service';
cn2en['计费'] = 'Billing';

cn2en['产品服务入口'] = 'Entrance';
cn2en['所有云产品和服务都可以在这里找到'] = 'All JDCloud products and services can be found here';
cn2en['自定义你的常用导航'] = 'Customize navigation';
cn2en['在这里设置常用菜单导航和进入产品页'] = 'Set up your navigation and access pages here.';
cn2en['常用云服务'] = 'Popular JDCloud services';
cn2en['方便切换和管理产品，拖动可排序'] = 'Customize your menu and drag to sort.';
cn2en['上一步'] = 'Last';
cn2en['下一步'] = 'Next';
cn2en['立即体验'] = 'Experience';
cn2en['最多收藏20个常用服务'] = 'No more than 20';
cn2en['当前账号已退出登录，请重新登录'] = 'You have been logged out. Please relogin and try again!';

cn2en['最近访问：'] = 'Recently visited: ';
cn2en['当前账号已退出登录，请重新登录！'] = 'The account is logged out. Please login again.';
cn2en['您还可能在找：'] = 'You might be looking for: ';

cn2en['跳过'] = 'skip';

cn2en['登录状态已改变'] = 'Login status changed';
cn2en['当前账号的登录状态已改变，页面即将刷新。'] = 'The login status of the current account has changed. The page is to be refreshed.';
cn2en['确定'] = 'OK';


export default cn2en;
