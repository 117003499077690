import Cookies from 'js-cookie';
import urlParse from 'url-parse';
import $script from 'scriptjs';
import * as logger from 'loglevel';
import { isArray, startsWith } from 'lodash-es';
import subAccountUrl from './config/subAccountAllowedUrlList';
import env from './env';

function isNull(value) {
  return value === '' || value === undefined || value === null || value === 'undefined' || value === 'null';
}

function appendPostfix(url, postfix) {
  if (!url) {
    return url;
  }
  // 忽略www的后缀添加
  if (url.indexOf('www.jcloud') !== -1 || url.indexOf('www.jdcloud') !== -1) {
    return url;
  }
  const list = [
    '//uc.jcloud.com',
    '//uc.jdcloud.com',
    '//cm-console.jcloud.com',
    '//cm-console.jdcloud.com',
    '//back.jcloud.com'
  ];
  for (let i = 0; i < list.length; i += 1) {
    if (startsWith(url, list[i])) {
      return url;
    }
  }

  let p = url.indexOf('.jdcloud.com');
  if (p === -1) {
    p = url.indexOf('.jcloud.com');
  }
  if (p === -1) {
    throw new Error('Invalid hostname');
  }
  const pre = url.substring(0, p);
  const post = url.substring(p + 1);
  return pre + postfix + '.' + post;
}

function openUrl(url, target) {
  // 如果点击的是语言切换
  if (url === 'cn' || url === 'en' || startsWith(url, '$setLang')) {
    // 例如，$setLang:cn $setLang:en
    const languageId = url.substring(url.indexOf(':') + 1);
    Cookies.set('jdcloud_sitelang', languageId, { domain: '.jdcloud.com', expires: 3650 });
    Cookies.set('jdcloud_sitelang', languageId, { domain: '.jcloud.com', expires: 3650 });
    Cookies.set('jdcloud_sitelang', languageId, { domain: '.jd.com', expires: 3650 });
    if (window.Ynavi) {
      window.Ynavi.invalidateCache();
    } else {
      location.reload();
    }
    return;
  }

  // 如果有后缀
  let newUrl = env.postfix !== '' ? appendPostfix(url, env.postfix) : url;
  // 不是语言切换
  logger.debug('跳转', newUrl);
  if (isNull(target)) {
    location.href = newUrl;
  } else {
    window.open(newUrl, target);
  }
}

export function getFlattenConfig(result, config, depth) {
  if (typeof depth !== 'number') {
    depth = 1;
  }
  if (isArray(config)) {
    config.forEach(({ page, url, childMenu }) => {
      let u;
      if (url) {
        u = urlParse(url);
      }
      result.push({
        page,
        url,
        depth,
        hostname: url ? u.hostname : '',
        pathname: url ? u.pathname : ''
      });
      childMenu && getFlattenConfig(result, childMenu, depth + 1);
    });
  } else if (config) {
    let u;
    if (config.url) {
      u = urlParse(config.url);
    }
    result.push({
      page: config.page,
      url: config.url,
      depth,
      hostname: config.url ? u.hostname : '',
      pathname: config.url ? u.pathname : ''
    });
  }
}

/**
 * 设置Cookie
 * @param key 键
 * @param value 值
 * @param expires 过期时间
 */
function setCookie(key, value, expires) {
  Cookies.set(key, value, { expires, domain: '.jdcloud.com' });
  Cookies.set(key, value, { expires, domain: '.jcloud.com' });
}

/**
 * 获取Cookie
 * @param key 键
 * @returns {*}
 */
function getCookies(key) {
  return Cookies.get(key);
}

/**
 * 移除Cookie
 * @param key 键
 */
function delCookies(key) {
  Cookies.remove(key);
}

/**
 *  判断是不是子账户URL
 *  @param {string} url 需要判断的URL
 */
function isSubAccountUrl(url) {
  if (isNull(url)) {
    return false;
  }
  url = url.toLowerCase();
  for (let i = 0; i < subAccountUrl.length; i++) {
    if (url.indexOf(subAccountUrl[i].toLowerCase()) >= 0) {
      return true;
    }
  }
  return false;
}

export async function loadScript(url) {
  return new Promise((resolve, reject) => {
    try {
      $script(url, () => {
        resolve();
      });
    } catch (e) {
      reject(e);
    }
  });
}

export {
  appendPostfix,
  isNull,
  isSubAccountUrl,
  openUrl,
  getCookies,
  delCookies,
  setCookie
};
