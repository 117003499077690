// import Bluebird from 'bluebird';
// window.Promise = Bluebird;

import env from '../env';

export function polyfill() {
  const loadPromises = [];
  if (env.msie) {
    loadPromises.push(
      import(/* webpackChunkName: "polyfill-html5shiv" */ 'html5shiv')
    );
  }

  if (!window.$) {
    loadPromises.push(
      import(/* webpackChunkName: "jquery" */ 'jquery').then(jquery => {
        window.$ = jquery.default;
      })
    );
  }

  loadPromises.push(
    import(/* webpackChunkName: "polyfill-console" */ 'console-polyfill')
  );
  loadPromises.push(
    import(/* webpackChunkName: "polyfill-querySelector" */ './querySelector.polyfill')
  );

  // 等待所有的polyfill执行完成
  return Promise.all(loadPromises);
}
