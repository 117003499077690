export function getNavibarConfig (_languageId) {
  if (_languageId === 'en') {
    return [
      { "name": "Home", "page": "outline", "url": "//console.jdcloud.com" },
      { "name": "Cloud Service", "page": "product", "url": "" },
      { "name": "Search", "page": "search", "childMenu": [], "url": "", "isAdminHide": 1 },
      { "name": "Cost", "page": "cost", "childMenu": [
        { "name": "Recharge ", "page": "recharge", "url": "//uc.jdcloud.com/cost/capital/recharge", "isAdminHide": 1 },
          { "name": "Orders", "page": "order", "url": "//order-console.jdcloud.com/cost/order-list", "isAdminHide": 1 },
          { "name": "Invoice", "page": "invoice", "url": "//invoice-uc.jdcloud.com/cost/invoice/list", "isAdminHide": 1 },
          { "name": "Coupon", "page": "coupon", "url": "//uc.jdcloud.com/cost/coupon", "isAdminHide": 1 },
          { "name": "Purchases Record", "page": "csmRecord", "url": "//cost.jdcloud.com/cost/consume/consume-history" },
          { "name": "Renew Management", "page": "csmManage", "url": "//renewal-console.jdcloud.com/renew", "isAdminHide": 1 },
          { "name": "Contract Management", "page": "contract", "url": "//contract.jdcloud.com/contract/apply/information-completion", "isAdminHide": 1 },
          { "name": "Recommend", "page": "CPS", "url": "//www.jdcloud.com/cn/activity/reward", "isAdminHide": 1 }
        ], "url": ""
      },
      { "name": "Ticket", "page": "form", "childMenu": [
          { "name": "Open Ticket", "page": "post", "url": "//uc.jdcloud.com/myorder/submit", "isAdminHide": 1 },
          { "name": "My Tickets", "page": "myForm", "url": "//uc.jdcloud.com/myorder/list", "isAdminHide": 1 }
        ], "url": "", "isAdminHide": 1
      },
      { "name": "ICP", "page": "record", "url": "//uc.jdcloud.com/record/index", "isAdminHide": 1 },
      { "name": "Partner", "page": "partnerInfo", "url": "//partner.jdcloud.com" },
      { "name": "Messages", "page": "contact", "url": "//uc.jdcloud.com/message/message-list", "isAdminHide": 1 },
      { "name": "Help Center", "page": "help", "url": "https://docs.jdcloud.com" },
      {
        "name": "English", "page": "lang", "childMenu": [
          { "name": "中文（简体）", "page": "cn", "url": "$setLang:cn" },
          { "name": "English", "page": "en", "url": "$setLang:en" }
        ], "url": ""
      },
      { "name": "", "page": "user", "url": "" }
    ]
  }
  else {
    return [
      // { "name": "概览", "page": "outline", "url": "//console.jdcloud.com" },
      { "name": "云服务", "page": "product", "url": "" },
      { "name": "搜索", "page": "search", "childMenu": [], "url": "", "isAdminHide": 1 },
      { "name": "费用", "page": "cost", "childMenu": [
        { "name": "充值", "page": "recharge", "url": "//uc.jdcloud.com/cost/capital/recharge", "isAdminHide": 1 },
          { "name": "订单", "page": "order", "url": "//order-console.jdcloud.com/cost/order-list", "isAdminHide": 1 },
          { "name": "发票", "page": "invoice", "url": "//invoice-uc.jdcloud.com/cost/invoice/list", "isAdminHide": 1 },
          { "name": "代金券", "page": "coupon", "url": "//uc.jdcloud.com/cost/coupon", "isAdminHide": 1 },
          { "name": "消费记录", "page": "csmRecord", "url": "//cost.jdcloud.com/cost/consume/consume-history" },
          { "name": "续费管理", "page": "csmManage", "url": "//renewal-console.jdcloud.com/renew", "isAdminHide": 1 },
          { "name": "合同管理", "page": "contract", "url": "//contract.jdcloud.com/contract/apply/information-completion", "isAdminHide": 1 },
          { "name": "推荐返利", "page": "cps", "url": "//www.jdcloud.com/cn/activity/reward", "isAdminHide": 1 }
        ], "url": ""
      },
      { "name": "工单", "page": "form", "childMenu": [
          { "name": "提交工单", "page": "post", "url": "//uc.jdcloud.com/myorder/submit", "isAdminHide": 1 },
          { "name": "我的工单", "page": "myForm", "url": "//uc.jdcloud.com/myorder/list", "isAdminHide": 1 }
        ], "url": "", "isAdminHide": 1
      },
      { "name": "备案", "page": "record", "url": "//uc.jdcloud.com/record/index", "isAdminHide": 1 },
      { "name": "渠道管理", "page": "partnerInfo", "url": "//partner.jdcloud.com" },
      { "name": "消息", "page": "contact", "url": "//uc.jdcloud.com/message/message-list", "isAdminHide": 1 },
      { "name": "帮助中心", "page": "help", "url": "https://docs.jdcloud.com" },
      { "name": "中文（简体）", "page": "lang", "childMenu": [
          { "name": "中文（简体）", "page": "cn", "url": "$setLang:cn" },
          { "name": "English", "page": "en", "url": "$setLang:en" }
        ], "url": ""
      },
      { "name": "", "page": "user", "url": "" }
    ]
  }
};