import * as logger from 'loglevel';
import env from './env';

const _config = {};

export class ConfigManager {
  set(name, value) {
    _config[name] = value;
  }

  get(name) {
    return _config[name];
  }

  inspect() {
    if (env.isDev) {
      logger.debug('ConfigManager=', _config);
    }
  }
}

export default new ConfigManager();
