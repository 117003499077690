export function getUserCenterConfig(_languageId) {
  if (_languageId === 'en'){
    return {
      "goBack": "Sign Out",
      "resetPassword": "Reset password",
      "logout": "Logout",
      "balance": "Account Balance",
      "arrear": "Arrears",
      "recharge": "Recharge",
      "securitySettings": "Settings",
      "accountManagement": "Account",
      "mfaAuth": "MFA Auth",
      "accessKeyManagement": "Access Key",
      "accessControl": "IAM Control",
      "verify":"goVerify>>"
    }
  }
  else{
    return {
      "goBack": '返回',
      "resetPassword": '重置密码',
      "logout": '退出',
      "balance": '余额',
      "arrear": '欠费',
      "recharge": '充值',
      "securitySettings": '安全设置',
      "accountManagement": '账户管理',
      "mfaAuth": 'MFA认证',
      "accessKeyManagement": 'Access Key管理',
      "accessControl": '访问控制',
      "verify":"立即认证>>"
    }
  }
}
