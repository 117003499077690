// import "babel-polyfill";
import * as logger from 'loglevel';
import Cookies from 'js-cookie';
import { JElementBizServerUrl } from './config.js'

import {
  bootstrap,
  checkHideSideMenu
} from './YnaviClass';
import env from './env'; // 检测环境
import {
  polyfill
} from './polyfill'; // polyfill
import {
  loadScript
} from './utils'; // utils
import ConfigManager from './ConfigManager'; // 配置管理器
import * as services from './services'; // 服务
import * as templates from './template'; // 模板

// window.__logger__ = logger;
logger.setLevel(env.isDev ? 'debug' : 'warn');
// logger.setLevel('debug');

/**
 * 并行加载配置项和样式
 * @return {Promise<any[]>}
 */
async function loadData() {
  const loadPromises = [];
  // 样式
  const styleP =
    import( /* webpackChunkName: "Ynavi-style" */ './assets/style/Ynavi.css');
  const styleToolkit =
    import( /* webpackChunkName: "Ynavi-toolkit-style" */ './assets/style/Toolkit.css');
  loadPromises.push(styleP);
  loadPromises.push(styleToolkit);

  // 如果当前是UC，那么加载UC配置
  // if (env.isUc) {
  //   const ucMenuP = loadScript('//storage.jd.com/console/cms/ucMenu').then(
  //     () => {
  //       ConfigManager.set('ucSideMenuObj', window.ucSideMenuObj);
  //     }
  //   );
  //   loadPromises.push(ucMenuP);
  // }

  // 加载控制台配置项
  const configP = services.getMenuConfig().then(config => {
    for (let p in config) {
      if (typeof config[p] === 'string' && config[p].indexOf('{') >= 0) {
        config[p === 'uc' ? 'ucSideMenuObj' : p] = JSON.parse(config[p]);
      } else {
        if (p === 'uc') config['ucSideMenuObj'] = config[p];
      }
    }
    config.favMenu = []
    ConfigManager.set('config', config);
  });
  loadPromises.push(configP);

  // 等待所有加载完成
  return Promise.all(loadPromises);
}

/**
 * 显示loading图标
 */
function showLoading() {
  const header = $('header').eq(0);
  header.addClass('YnaviHead');

  $(header).html(
    templates.TopNaviOnlyLogo({
      languageId: Cookies.get('jdcloud_sitelang'),
      bizServerConfig: window.bizServerConfig
    })
  );

  $(".pre-loading").html('<i class="pre-loading-icon"></i>')

  // checkHideSideMenu(isHideSideMenu => {
  //   if (!isHideSideMenu) {
  //     $('.mainMenu').html(
  //       '<div class="pre-loading"><i class="pre-loading-icon"></i></div>'
  //     );
  //   }
  // });
}

async function loadBizServerConfig () {
  return new Promise((resolve, reject) => {
    $.ajax({
      url: `${JElementBizServerUrl}/nodeApi/config/describeGeneralConfigurable`,
      jsonp: 'callback',
      dataType: 'jsonp',
      success: function (data) {
        console.log('获取bizServer的配置文件')
        window.bizServerConfig = data
        resolve()
      },
      error () {
        console.error('获取bizServer配置文件失败')
        reject()
      }
    })
  })
}



/**
 * Ynavi入口点
 * @return {Promise<void>}
 */
async function main() {
  await loadBizServerConfig()
  // 载入数据
  await loadData().then(() => {
    ConfigManager.inspect();
    logger.debug('载入数据完成');
  });

  // 初始化
  await bootstrap();

  logger.debug('菜单初始化完成');
}

/**
 * 入口点(entry point)
 */
(() => {
  // 友情提示：这里不能写成 async 函数，因为有可能还没有 Promise
  // 需要等待 polyfill() 执行完成后，才能保证有 Promise
  polyfill()
    .then(() => {
      logger.debug('polyfill完成');
      showLoading();
    })
    .then(main);
})();
