/*目前是静态文件，某些变量临时通过替换的方式配置化，先放到这个文件里*/
export const CnApiUrl = window.TO_BE_REPLACE_CN_API_URL || "//cn-api.jdcloud.com"
export const UcApiUrl = window.TO_BE_REPLACE_UC_API_URL || "//uclogin-inner.jdcloud.com"
export const yunjianOverview =window.TO_BE_REPLACE_YUNJIAN_OVERVIEW || "/t-paas/dashboard"
export const currentEnv = window.TO_BE_REPLACE_CURRENT_ENV || ""

// 通过biz-server获取静态资源信息
export const JElementBizServerUrl = window.TO_BE_REPLACE_BIZ_SERVER_URL || "//jelement-biz-server.jdcloud.com"
// 是否显示账户余额
export const showAccountBalance = ['true', true].includes(window.TO_BE_REPLACE_SHOW_ACCOUNT_BALANCE ?? true)
// 是否显示消息中心
export const showMessage = ['true', true].includes(window.TO_BE_REPLACE_SHOW_MESSAGE ?? true)
// 是否开启实名认证
export const openRealName = ['true', true].includes(window.TO_BE_REPLACE_OPEN_REAL_NAME ?? true)
// 是否支持埋点
export const supportBuryingPoints = ['true', true].includes(window.TO_BE_REPLACE_BURYING_POINTS ?? true)
// 是否加载菜单字体文件
export const loadMenuFontSize = ['true', true].includes(window.TO_BE_REPLACE_MENU_FONT_SIZE ?? true)
// 子帐号登录地址
export const subUserLoginUrl = window.TO_BE_REPLACE_SUB_USER_LOGIN_URL || "//uc.jdcloud.com"
// 主帐号登录地址
export const mainLoginUrl = window.TO_BE_REPLACE_MAIN_LOGIN_URL || "//login.jdcloud.com"
// 概览页地址
export const overviewUrl = window.TO_BE_REPLACE_OVERVIEW_URL || "//console.jdcloud.com"
// 是否为集成环境
export const isPoweredByApp = window.__JDCLOUD_NAMESPACE__ === 'yunjian' ? true: ['true', true].includes(window.TO_BE_REPLACE_POWERED_BY_APP ?? false)
// 是否是私有化
export const isPrivate = ['true', true].includes(window.TO_BE_REPLACE_IS_PRIVATE ?? false)
// 是否显示安全警告
export const showWarn = ['true', true].includes(window.TO_BE_REPLACE_SHOW_WARN ?? true)
// 是否显示工具栏
export const showToolkit = ['true', true].includes(window.TO_BE_REPLACE_SHOW_TOOLKIT ?? true)
