import { includes, toLower } from 'lodash-es';
import Cookies from 'js-cookie';
import cn2en from './cn2en';

const cn2enKeys = Object.keys(cn2en);

export function getTranslation(word) {
  const sitelang = toLower(Cookies.get('jdcloud_sitelang') || 'cn');

  // cn不翻译
  const cnLikeLanguages = ['cn', 'zh-cn', 'zh-tw', 'zh-hk'];
  if (includes(cnLikeLanguages, sitelang)) {
    return word;
  }

  // en和其他语言进行翻译
  if (includes(cn2enKeys, word) && cn2en[word]) {
    return cn2en[word];
  }
  return word.replace('①','');
}
