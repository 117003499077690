// 对数据云使用的文龙版控制台菜单进行兼容
import { getTranslation } from './i18n';
import urlParse from 'url-parse';
import Cookies from 'js-cookie';
import { template } from 'lodash-es';
import * as templates from './template';

export const injectWenlongCompatible = () => {
  window.addMenu = (parentId, menuList, replace, title, url) => {
    Cookies.set('currPage', '', {
      domain: '.jdcloud.com'
    });
    let addMenuHandle = setTimeout(()=>{
      clearTimeout(addMenuHandle)
      const ynavi = window.Ynavi;
      if (!ynavi || menuList.length <= 0) {
        return;
      }
      const urlObj = urlParse(location.href);
      const protocol = urlObj.protocol;
      const currentUrl = urlObj.href.substring(protocol.length);
      $('.subMenu').html(
        templates.WenlongSubMenu({
          subTitle: title,
          subMenu: menuList,
          currentUrl: currentUrl,
          getTranslation : getTranslation
        })
      ).removeClass('none').addClass('spread');
      const subMenuList = $('.subMenu .menuBox a');
      let isSomeLinkCur = false;
      subMenuList.each(function(i, el) {
        if (!isSomeLinkCur && $(el).hasClass('cur')) {
          isSomeLinkCur = true;
        }
      });
      if (!isSomeLinkCur) {
        subMenuList[0] && $(subMenuList[0]).addClass('cur');
      }
      $('.subMenu .subMenuBox').css('top', ($('.subMenu .subMenu--title-wl').get(0).offsetHeight) + 'px')
      ynavi.showSubMenu();
    },100)
  };

  window.changeMenuOpenStatus = (isMainOpen, isSecondOpen) => {
    const ynavi = window.Ynavi;
    if (!ynavi) {
      return;
    }
    //ynavi.toggleSubMenu_n(isSecondOpen);
  };
};
