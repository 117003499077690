// 跳转函数集中管理
// 需要
// 1. 自适应http和https
// 2. 自适应测试用后缀
// 3. 自适应域名jdcloud和jcloud
import Cookies from 'js-cookie';
import env from './env';
const { domain, postfix } = env;

// 也就是http:或者https:，其他情况也运行不了
const protocol = location.protocol;

// "当前页面不支持子用户访问"
function gotoNotSupportSubAccount() {
  const notSupportSubAccount = `${protocol}//console${postfix}.${domain}/accountErr`;
  location.replace(notSupportSubAccount);
}

// UC登录页
function gotoUcLogin(returnUrl) {
  if (!returnUrl) {
    returnUrl = location.href;
  }
  const encodedReturnUrl = encodeURIComponent(returnUrl);
  location.href = `${protocol}//uc${postfix}.${domain}/login?returnUrl=${encodedReturnUrl}`;
}

function logout() {
  const adminPin = Cookies.get('account');

  // 登出jdcloud

  // 登出jcloud
}

export { gotoNotSupportSubAccount, gotoUcLogin };
