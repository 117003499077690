import jsonpPromise from 'jsonp-promise';
import sha256 from 'sha256';
import {
  includes,
  isArray,
  keys,
  startsWith
} from 'lodash-es';
import Cookies from 'js-cookie';
import loadJS from 'load-js';
import store from 'store';
import * as logger from 'loglevel';
import qs from 'qs';
import randomString from 'random-string';
import { getNavibarConfig } from './config/navbarConfig';
import { getUserCenterConfig } from './config/userCenterConfig';
import { currentNamespace } from './env';
import { CnApiUrl, UcApiUrl, isPrivate, mainLoginUrl, subUserLoginUrl } from './config'

let _languageId = Cookies.get('jdcloud_sitelang');
_languageId = _languageId ? _languageId : 'cn'
let _lanSymbol = _languageId === 'cn' ? '' : `_${_languageId}`;
const jsonp = function(url, options){
  url += (~url.indexOf('?') ? '&' : '?') + 'isMainMenu=1';
  url = url.replace('?&', '?');
  return jsonpPromise(url, options)
}
// 生产
const menuLocalHost = '//static2.jdcloud.com' + (location.port == '80' && location.hostname === 'static2.jdcloud.com' ? '' : (':'+location.port));
const menuNetHost = location.protocol === 'http:' ? '//menu.s3.cn-east-2-stag.jcloudcs.com' : '//menu.s3.cn-north-1.jdcloud-oss.com';
// mock
// const CnApiUrl =  protocol + '//cn-api.jdcloud.com:3000';
// 预发
// const CnApiUrl = protocol + '//cn-api.jdcloud.com';
const configDataKey = 'YnaviConfig';
const lastModifiedKey = 'YnaviConfigLastModified';

async function loadScript(url) {
  return loadJS([{
    url: url,
    async: true,
    cache: false
  }]);
}

async function getUserPermissionTable(pin) {
  if (!pin) {
    return {};
  }
  const hashedPin = sha256(pin);
  try {
    await loadScript(menuNetHost + '/userConfigSafe.js');
  } catch (_err) {
    if (isPrivate) {
      console.error('getUserPermissionTable failed')
      return
    }
    await loadScript(menuLocalHost + '/userConfigSafe.js');
  }
  let config = window.config;
  const configKeys = keys(config);
  for (let i = 0; i < configKeys.length; i += 1) {
    const item = config[configKeys[i]];
    config[configKeys[i]] = !!(isArray(item) && includes(item, hashedPin));
  }
  return config;
}

function filterSideMenuObj(sideMenuObj, permissions, adminPin) {
  let isSubAccount = false;
  if (adminPin === 'null') {
    isSubAccount = false;
  } else {
    isSubAccount = adminPin !== '';
  }

  function filterSideMenuObjImpl(menuList) {
    if (isArray(menuList)) {
      const result = [];
      for (let i = 0; i < menuList.length; i += 1) {
        const item = menuList[i];
        if (item.childMenu && item.childMenu.length > 0) {
          item.childMenu = filterSideMenuObjImpl(item.childMenu);
        }
        if (item.isGray === 1) {
          // 是否是灰度产品线
          const grayName = item.isGrayProduct;
          if (grayName && permissions[grayName]) {
            result.push(item);
          }
        } else if (!isSubAccount) {
          // 如果不是子账户，那么可见
          result.push(item);
        } else if (isSubAccount && item.isAdminHide === 0) {
          // 如果是子账户，并且isAdminHide===0，说明对子账户可见
          result.push(item);
        }
      }
      return result;
    } else {
      throw new Error('[filterSideMenuObjImpl] Should not reach here');
    }
  }
  return filterSideMenuObjImpl(sideMenuObj);
}

async function createMenuConfigFromSideMenuObj(sideMenuObj, ucMenuObj) {
  const pin = Cookies.get('pin') || '';
  const adminPin = Cookies.get('account') || '';
  const permissions = await getUserPermissionTable(pin);
  const filteredSideMenuObj = filterSideMenuObj(
    sideMenuObj,
    permissions,
    adminPin,
  );
  return {
    menuConfig: filteredSideMenuObj,
    pin: pin,
    adminPin: adminPin,
    navBarConfig: getNavibarConfig(_languageId),
    userCenterConfig: getUserCenterConfig(_languageId),
    ucSideMenuObj: ucMenuObj
  };
}

function getRandomString(length) {
  return randomString({
    length
  });
}

async function getMenuDataOtherPath() {
  // 回落到CMS上的菜单数据，数据为全套数据，此时不支持语言切换和灰度认证
  if (isPrivate) return []
  try {
    await loadScript(`${menuNetHost}/consoleMenu${_lanSymbol}.js`);
    await loadScript(`${menuNetHost}/ucMenuConfig${_lanSymbol}.js`);
    const result = await createMenuConfigFromSideMenuObj(window.sideMenuObj, window.ucSideMenuObj);
    logger.debug('配置来源：storage');
    store.set(configDataKey, result);
    store.set(lastModifiedKey, +new Date());
    logger.debug('配置来源：CMS');
    return result;
  } catch (ex) {
    // 回落到自带的菜单数据
    logger.error('CMS error:', ex);
    logger.info('Fallback to self-config');
    try {
      await loadScript(`${menuLocalHost}/consoleMenu${_lanSymbol}.js`);
      await loadScript(`${menuLocalHost}/ucMenuConfig${_lanSymbol}.js`);
      const result = await createMenuConfigFromSideMenuObj(
        window.sideMenuObj, window.ucSideMenuObj
      );
      logger.debug('配置来源：工程内部');
      store.set(configDataKey, result);
      store.set(lastModifiedKey, +new Date());
      logger.debug('配置来源：self');
      return result;
    } catch (ex) {
      logger.error('self error:', ex);
      logger.error(
        "[Critical Error] Cannot fetch menu config. Maybe service is down or there's no Internet connection."
      );
    }
  }
}

async function getUCMenuDataOtherPath() {
  if (isPrivate) return []
  // 回落到CMS上的菜单数据，数据为全套数据，此时不支持语言切换和灰度认证
  try {
    await loadScript(menuNetHost + '/ucMenuConfig${_lanSymbol}.js');
    logger.debug('UC配置来源：storage');
    return window.ucSideMenuObj;
  } catch (ex) {
    // 回落到自带的菜单数据
    logger.error('CMS error:', ex);
    logger.info('Fallback to self-config');
    try {
      await loadScript(menuLocalHost + '/ucMenuConfig${_lanSymbol}.js');
      logger.debug('UC配置来源：工程内部');
      return window.ucSideMenuObj;
    } catch (ex) {
      logger.error('self error:', ex);
    }
  }
}

export async function getMenuConfig() {
  // 取菜单服务数据
  function getMenuConfigImpl() {
    const {
      promise
    } = jsonp(`${CnApiUrl}/menu/getUserMenu?namespace=${currentNamespace}`, {
      timeout: 30000,
      prefix: '_' + getRandomString(16)
    });
    return promise;
  }

  const cachedConfigData = store.get(configDataKey);
  const lastModified = store.get(lastModifiedKey);
  const now = +new Date();
  const expires = 60 * 1000; // 过期时间，单位秒

  // 如果有数据并且没有过期，那么使用缓存中的数据
  // 暂时禁用缓存
  // if (cachedConfigData && lastModified && now - lastModified <= expires) {
  //   logger.debug('配置来源：缓存', { lastModified: new Date(lastModified) });
  //   return cachedConfigData;
  // }

  // 否则获取数据
  let p;
  try {
    p = await getMenuConfigImpl();
    if (p.code === 0) {
      if (p.result) {
        if (!p.result.menuConfig) p.result.menuConfig = []
        if (!p.result.navBarConfig) p.result.navBarConfig = getNavibarConfig(_languageId)
        if (!p.result.userCenterConfig) p.result.userCenterConfig = getUserCenterConfig(_languageId)
      }
      store.set(configDataKey, p.result);
      store.set(lastModifiedKey, +new Date());
      logger.debug('配置来源：菜单服务');
      // 获取官网菜单数据
      // let _product = await getProductData()
      // if(_product.status === 0){
      //   p.result.product = _product.extChildren[0].extChildren
      // }
      if (!p.result.uc) {
        p.result.ucSideMenuObj = getUCMenuDataOtherPath()
      }
      return p.result;
    } else {
      if (p.code === 10001) {
        location.href = `${mainLoginUrl}?returnUrl=${encodeURIComponent(location.href)}`
        return
      }
      logger.error('Ynavi get Menu Data Error:', p.msg)
      return getMenuDataOtherPath()
    }
  } catch (ex) {
    logger.error('Ynavi service error:', ex);
    logger.info('Fallback to CMS config');
    return getMenuDataOtherPath()
  }
}

export function getFavMenu () {
  const {
    promise
  } = jsonp(`${CnApiUrl}/favor/query?namespace=${currentNamespace}`, {
    timeout: 8000,
    prefix: '_' + getRandomString(16)
  });
  return promise
}

export function saveFavMenu(_favors) {
  const {
    promise
  } = jsonp(`${CnApiUrl}/favor/save?favors=${_favors}&namespace=${currentNamespace}`, {
    timeout: 8000,
    prefix: '_' + getRandomString(16)
  });
  return promise
}

export function getVisitPermission(_url) {
  const {
    promise
  } = jsonp(`${CnApiUrl}/menu/permission?checkUrl=${_url}&namespace=${currentNamespace}`, {
    timeout: 8000,
    prefix: '_' + getRandomString(16)
  });
  return promise;
}

/**
 * 删除消息
 */
export async function delNgDomainMsg() {
  const {
    promise
  } = jsonp(`${subUserLoginUrl}/u/v2/message/delNgDomainMsg`, {
    timeout: 8000,
    prefix: '_' + getRandomString(16)
  });
  return promise;
}
// 私有化不显示欠费逻辑，暂时不处理
export async function getBalanceAndArrears() {
  const {
    promise
  } = jsonp(
    `${location.protocol === 'http:' ? '//console-stag' : '//console'}.jdcloud.com/localAPI/jsonp/balance`, {
    timeout: 8000,
    prefix: '_' + getRandomString(16)
  }
  );
  return promise;
}
export async function getTotalCount() {
  const {
    promise
  } = jsonp(`${subUserLoginUrl}/u/v2/message/getTotalCount/0/`, {
    timeout: 8000,
    prefix: '_' + getRandomString(16)
  });
  return promise;
}
export async function popMsg() {
  const {
    promise
  } = jsonp(`${subUserLoginUrl}/u/message/popMsg`, {
    timeout: 8000,
    prefix: '_' + getRandomString(16)
  });
  return promise;
}
export async function getNgDomainMsg() {
  const {
    promise
  } = jsonp(`${subUserLoginUrl}/u/v2/message/getNgDomainMsg`, {
    timeout: 8000,
    prefix: '_' + getRandomString(16)
  });
  return promise;
}
export async function markMsgHaveRead(messageId) {
  const {
    promise
  } = jsonp(`${subUserLoginUrl}/u/message/markMsgHaveRead`, {
    timeout: 8000,
    prefix: '_' + getRandomString(16),
    param: qs.stringify({
      infoId: messageId
    }) + '&callback'
  });
  return promise;
}

// 企业账户拦截
export async function accountIntercept() {
  const {
    promise
  } = jsonp(`${mainLoginUrl}/isInterceptUser`, {
    timeout: 8000,
    prefix: '_' + getRandomString(16),
  });
  return promise;
}

// 用户详情-当前登录用户信息
export async function describeUserPin (_param) {
  const {
    promise
  } = jsonp(`${UcApiUrl}/u/user/describeRightUserInfo`, {
    timeout: 8000,
    prefix: '_' + getRandomString(16),
    param:`referer=${location.href}&callback`
  });
  return promise;

}

